@import "_variables";
/*********************************************************************************/
/* @FONT-FACE                                                                    */
/*********************************************************************************/

@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i');

/*********************************************************************************/
/* IMAGES                                                                        */
/*********************************************************************************/

/* removes unsightly IE image link stuff */

a:focus {
  outline: none;
}

a img {
  border: none;
}

/*********************************************************************************/
/* TYPOGRAPHY                                                                    */
/*********************************************************************************/

body {
  font-size: 1.8rem;
  line-height: 1.65em;
  overflow-x: hidden;
}

p {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.65em;
  font-style: normal;
  color: $body_text_color;
}

h1 {
  font-family: "Audiowide", Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.7rem;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-style: normal;
  letter-spacing: 0.2rem;
  color: $secondary_color;
  &.page-title{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.full-screen-billboard{
  /*padding-top: 15vh!important;
  padding-bottom: 10vh!important;*/
  .action-buttons{
    margin-left: 0;
    width: 100%;
  }
  h2{
    font-family: $brand_font_2;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    .top-line, .middle-line{
      text-align: center;
      display: block;
    }
    .top-line{
      font-size: 2.4rem;
      letter-spacing: 4px;
      border-bottom: 6px $secondary_color solid;
      padding-bottom: 25px;
      margin-bottom: 25px;
      width: 60%;
      margin-left: 20%;
      margin-right: 20%;
      @media(max-width: 767px){
        font-size: 1.5rem;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
    .middle-line{
      font-size: 4.4rem;
      letter-spacing: 4px;
      line-height: 4.3rem;
      margin-top: 35px;
      @media(max-width: 767px){
        font-size: 2.5rem;
        line-height: 2.8rem;
      }

    }
  }
  h3{
    margin: 0;
    font-family: "Source Sans Pro",Helvetica,sans-serif;
    .third-line, .fourth-line{
      text-align: center;
      display: block;
    }
    .third-line{
      margin: 25px 0 40px 0;
      font-size: 1.7rem;
      text-transform: none;
      font-weight: 400;
      @media(max-width: 767px){
        margin: 25px 0 25px 0;
      }
    }
    .fourth-line{
      font-size: 1.2rem;
      text-transform: none;
      font-weight: 600;
    }
  }
}

.title-image-back.dark h1{
  color: $secondary_color;
}
.title-image-back{
  height:auto!important;
}
.title-image-back h1 {
    font-weight: 400!important;
    font-size: 3.2rem!important;
    line-height: 110%!important;
    letter-spacing: 0.2rem!important;
    @media(max-width: 767px){
      font-size: 2.2rem!important;
    }
}

.title-image-back .tag {
  left:0!important;
  max-width: 100%!important;
  position:relative!important;
  padding:25px 0px;
  @media(max-width: 767px){

  }
}

h2 {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.9rem;
  font-size: 1.9rem;
  font-style: normal;
}

h3 {
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h4 {
  font-family: "Audiowide", Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.9rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-style: normal;
  letter-spacing: 0.2rem;
}

h5 {font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-weight: 700;
  line-height: 1.3em;
  font-size: 1.3rem;
  font-style: normal;
  color: #444;
}

.btn{
  border-radius: 4px;
  font-family: $brand_font_2;
  font-size: .95rem;
  font-weight: normal;
  text-transform: uppercase;
  padding: 15px 50px;
}
a[class*="btn-white-"]{
  color: #FFFFFF!important;
}

.content-columns{
  a, a:visited {
    color: $secondary_color;
    text-decoration: none;
    border-bottom: 1px solid $secondary_color;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  a:hover, a:active {
    color: $secondary_color;
    text-decoration: none;
    border-bottom-color: transparent;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
  }
}

strong {
  font-weight: 600;
}

/* NAVBAR STYLES ********************************************************************************/
#subnav-menu{
  padding:0;
  margin: 0;
}
#subnav-toggle{
  background-color: $primary_color;
  .navbar-toggle{
    font-size: 2.2rem;
    background-color: transparent;
    color: #FFFFFF;
    margin: 0px auto;
    float: none;
    &:before{
      content: "SUBMENU";
      font-size: 2.2rem;
      margin-bottom: 0rem!important;
      font-weight: bold;
      width: 100%;
      float: left;
      height: 20px;
    }
  }
}
nav.navbar.bootsnav{
  .navbar-toggle{
    float: right;
    margin-right: 0px;
    margin-top: 30px;
    font-size: 1.7rem;
    &:before{
      content: "MENU";
      font-size: .7rem;
      font-weight: bold;
      margin-bottom: .1rem!important;
      width: 100%;
      float: left;
      height: 20px;
    }
  }

  @media(min-width: 992px){
    ul.nav{
      position: absolute;
      right: 0;
      bottom: 15px;
    }

    ul.nav li {
      display: inline;
      padding-right: 20px;
    }
    ul.nav li:last-child {
      padding: 0;
    }
  }

  ul.nav li a, ul.nav li a:visited {
    font-size: 0.9rem;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    line-height: 120%;
    color: $primary_color;
    text-align: right;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    border: none;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }


  ul.nav li.current-page-ancestor a, ul.nav li.current-menu-item a{
      color: #868B96;
    }

  ul.nav li a:hover, ul.nav li a:active {
    color: $secondary_color;
    text-decoration: none;
    text-decoration: none;
    border: none;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
  }
  .navbar-brand{
    max-height: 170px;
    max-width: 80%;
    height: auto;
    @media(max-width: 991px){
      width: 70%;
    }
    img{
      max-height: 140px;
      transition:all 0.4s ease;
    }
  }

  &.sticked{
    .navbar-brand{
      max-height: 120px;
      height: auto;
      img{
        max-height: 90px;
        transition:all 0.4s ease;
      }
    }
  }
}
/* END NAVBAR STYLES ********************************************************************************/


/* SUBNAV STYLES ********************************************************************************/

.subnav-wrapper{
  background: $primary_color;
  padding: 15px 0px;
  text-align: center;
  @media(max-width: 991px){
    /*background: $primary_color url('/wp-content/themes/nyp-theme/img/subnav-arrow.png') no-repeat center right;
    background-size: 4%;
    padding: 0px 15px 10px!important;*/

    .container{
      white-space: nowrap;
      overflow-y: scroll; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
      position: relative;
    }
  }
  .scroll-arrow{
    @media(max-width: 991px){
    position: absolute;
    right: 0;
    z-index: 12500;
    top: 15px;
    }
  }
  /*&:after{
    @media(max-width: 991px){
      content: "\f101";
    font-family: FontAwesome;
    width: 25px;
    height: 25px;
    color: rgba(255,255,255,0.6);
    background-color: transparent;
    position: absolute;
    right: 0;
    z-index: 12500;
    top: 15px;
    }
  }*/
  .subnav{
    //width: 100%;
    @media(min-width: 992px){
      padding: 0 15px;
    }
    li{
      text-align: center;
      display: inline;
      padding: 0 20px;
      @media(max-width: 991px){
        display: inline-block;
        width: 100%!important;
        float: none;
        padding: 0px 0!important;
      }
      a{
        color: #FFFFFF;
        height: auto;
        font-family: $global_font;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1rem;
        vertical-align: top;
        text-align: left;
        padding:0rem!important;
        @media(max-width: 991px){
          padding-right: 1em;
          padding-left: 1em;
        }

        &.active, &:hover{
          color: $alt_color;
        }
      }
    }

    &.events-subnav li{
      padding: 0 12px !important;
    }
  }
}

/* END SUBNAV STYLES ********************************************************************************/


/* BILLBOARD STYLES ********************************************************************************/

.action-billboard{
  &.underline-title{
    h2{
      padding-bottom: 10px;
      display: inline-block;
      border-bottom: 4px $alt_color solid;
    }
  }
  h2{
    font-family: $brand_font_2;
    font-size: 1.75rem;
    font-weight: normal;
    line-height: 2.25rem;
    text-transform: uppercase;
  }
  p{
    font-family: $global_font;
    font-size: 1.5rem;
    font-weight: normal;
  }
}

/* END BILLBOARD STYLES ********************************************************************************/


.subscribe-bar{
  cursor: pointer;
  padding: 10px 0 10px 0;
  background-color: $alt_color;
  h3{
    color: #FFFFFF;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.15rem;
    text-transform: none;
    text-align: center;
  }
}
/* ANNOUNCEMENT STYLES ********************************************************************************/

.announcement{
  background-color: $secondary_color;
  padding: 40px 0 60px 0;
  h3, p, a{
    color: #FFFFFF!important;
  }
  h3{
    font-weight: normal;
    font-family: $brand_font_2;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  p{
    font-weight: normal;
  }
  a{
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    &:hover{
      color: #FFFFFF;
    }
  }
}
/* END ANNOUNCEMENT STYLES ********************************************************************************/

/* PROGRAM STYLES ********************************************************************************/
.program{
  .no-margin-grid-block{
    .block.has_link.active *{
      text-shadow: none;
      h3{
        &:after{
            -webkit-transform: translateX(8px);
            transform: translateX(8px);
        }
        span{

        }
      }
    }
    .content{
      background-color: #E7E7E7;
      width: auto;
      min-width: 50%;
      bottom: 0;
      left: 0;
      @media(max-width: 767px){
        width: 100%;
      }
      h3{
        background-color: transparent;
        display: block;
        font-family: $brand_font_2;
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.75rem;
        text-align: right;
        padding: 0;
        &:after{
          content: '\f101';
          font-family: FontAwesome;
          background-color: #A42A27;
          padding: 10px 12px 10px 18px;
          color: #FFF;
          margin-left: 15px;
          margin-right: -1px;
          display: inline-flex;
          vertical-align: middle;
          -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px transparent;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-property: transform;
          transition-property: transform;
        }
        span{
          color: #3B4253!important;
          padding-left: 15px;
        }
      }
    }
  }
}

/* END PROGRAM STYLES ********************************************************************************/

/* EVENT STYLES ********************************************************************************/
  .event-row{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .event{
    margin-bottom: 25px;
    &:first-child{
      flex:1;
    }
    .event-name{
      font-size: 1.1rem;
      color: $secondary_color;
      line-height: 1.5rem;
      font-weight: bold;
      margin: 5px 0;
      text-transform: capitalize;
    }
    .event-date{
      color: $primary_color;
      font-size: 1.1rem;
      line-height: 1.5rem;
      font-weight:normal;
      float: left;
      width: 100%;
    }
  }

  .event-details{
    .dates{
      margin:25px 0;
    }
    .gallery{
      margin: 50px 0;
    }
  }

/* END EVENT STYLES ********************************************************************************/

/* MISSIONARY STYLES ********************************************************************************/

  .missionaries{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .missionary{
      margin-bottom: 25px;
      img{
        @media(min-width:481px) and (max-width: 767px){
          max-width: 350px;
          margin: auto;
        }
      }
      text-align: center;
      h3{
        font-size: 1.25rem;
        color: $secondary_color;
        line-height: 1.5rem;
        font-weight: bold;
        margin: 5px 0;
        text-transform: capitalize;
      }
      span.position{
        color: $primary_color;
        font-size: 1.1rem;
        line-height: 1.5rem;
        font-weight:normal;
        display: block;
        width: 100%;
      }
      a.learn-more{
        color: $secondary_color;
        font-size: 1.1rem;
        text-decoration: underline;
      }
    }
  }

  .missionary-details{
    .bio{

    }
  }

/* END MISSIONARY STYLES ********************************************************************************/

/* FOOTER STYLES ********************************************************************************/
.footer-container{
  overflow: hidden;

  .custom-footer{
    background: #FFFFFF;
    background-size: 25%;
    border-top: 7px #569BAF solid;
    padding: 60px 15px;
    position: relative;
    .footer-logo{
      padding: 0 25px;
      @media(max-width: 991px){
        padding: 15px 25px;
      }
    }
  }
  .sub-footer{
    background-color: $primary_color;
    padding: 15px;
    p, a{
      color: #FFFFFF;
      font-size: .95rem!important;
      font-weight: normal;
      margin-top: 5px;
      @media(max-width: 767px){
        text-align: center;
      }
    }
    .social-icons{
      @media(max-width: 767px){
        text-align: center;
        ul{
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
    .svg-inline--fa{
      font-size: 2.1rem;
    }

    ul{
      display: inline;
      margin-left: 0;
      padding-left: 0;
      li{
        padding: 0 10px;
        display: inline;
        a{
          color: #FFFFFF;
          font-size: 1.8rem;
        }
      }
    }
  }
}


/* END FOOTER STYLES ********************************************************************************/

.donate{
  background-color: #E7E7E7;
  padding: 50px 0;
}

  .modal-dialog{
    margin: 20vh auto;
  }

.projects, .departments{
  .no-margin-grid-block{
    cursor: pointer;
    a{
      cursor: pointer;
      &:hover{
        text-decoration: none;
      }
    }
    &:hover{
      a *{
        text-decoration: none!important;
      }
    }
    .content{
      cursor: pointer;
      left: 15px;
      right: 15px;
      padding: 15px;
    }
    h3, .description{
      background-color: rgba(86,155,175,.75);
      cursor: pointer;
    }
    h3{
      font-size: 1.5rem!important;
      display: block;
    }
    .description{
      clear: both;
      font-size: 1.1rem;
      margin-top: 15px;
      padding: 5px 10px;
      bottom: 30px;
    }
    p{
      font-size: 1.1rem!important;
      background-color: transparent;
      margin-top: 0!important;
      bottom: auto!important;
      padding: 0!important;
      font-size: 1.1rem!important;
    }
    a{
      color: #bf2522!important;
      font-weight: bold;
    }
  }
}

#mainIMG img{
  transition:opacity 0.5s linear;
  margin: auto;
}
.thumbnails{
  margin-top: 10px;
}
.thumbnails li > a{
  float: left;
}
.thumbnails li > a > img{
  opacity: 0.55;
}
.thumbnails li > a.active-image > img{
  opacity: 1.0;
}
.ginput_container_name{
  p{
    width: 50%;
    float: left;
    span.name_first, .name_last{
      float: left;
      width: 100%!important;

    }
  }

}

.addresses-column{
  margin-bottom: 30px;
  text-align: center;
  h2{
    color: $secondary_color!important;
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  p{
    font-size: 1rem;
    margin-bottom: 0;
    &.small-label{
      font-weight: bold;
      margin-top: 15px;
    }
    &.number{
      font-size: 1.6rem;
      line-height: 1.8rem;
      span{
        font-weight: bold;
      }
    }
  }
}
.request-sidebar{
  background-color:#e8e8e8;
  padding-top: 0.7em;
  padding-bottom: 0.5em;
  padding-left: .5em;
  padding-right: .5em;
}


/* FORM STYLE*/
.ginput_container input, .ginput_container textarea{
  background-image: none!important;
  background-repeat: no-repeat;
  background-attachment:fixed;
  background-size: auto;
  background-position: center;
  border-radius: 4px!important;
  padding: 2px 4px!important;
  border:1px $body_text_color solid!important;
}
.gform_wrapper{
  label.gfield_label{
    font-size: 1.2rem!important;
  }
  .gfield_required{
    color: $secondary_color!important;
  }
}

.archived-orders-container{
  background-color: #f9f9f9;
  border:2px #000 solid;
  border-radius: 12px;
  padding: 25px;
}
.newsletter-archive{
  hr{
    border-bottom: 3px $primary_color solid;
    line-height: 2.5rem;
    margin-top: 10px;
  }
}
.newsletter-archive-block{
  margin-bottom: 40px;
  p.date{
    color: $alt_color;
  }
  .btn{
    border-radius: 4px;
    font-family: Audiowide,Helvetica,sans-serif;
    font-size: .65rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 8px 20px;
  }
}
.newsletter-block{
  margin-bottom: 40px;
  p{
    font-size: 1.1rem!important;
    line-height: 1.35rem!important;
  }
  .btn{
    border-radius: 4px;
    font-family: Audiowide,Helvetica,sans-serif;
    font-size: .65rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 8px 20px;
  }
  h3{
    text-transform: none;
  }
}

.sidebar{
  p{
    font-size: 1.1rem!important;
    line-height: 1.35rem!important;
  }
  ul{
    padding-left: 0;
    li{
      list-style-type: none;
      a{
        color: $secondary_color;
        font-weight: bold;
        &.active{
          color: $primary_color;
        }
      }
    }
  }
  .btn{
    border-radius: 4px;
    font-family: Audiowide,Helvetica,sans-serif;
    font-size: .65rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 8px 20px;
  }
}

.gform_wrapper form input, .gform_wrapper form input[type=text], .gform_wrapper form select, .gform_wrapper form textarea{
  width: 90%!important;
}
